<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <!-- <vuexy-logo /> -->
        <b-img
          :src="require('@/assets/images/avatars/13-small.png')"
          style="width: 30px; height: 30px"
          alt="logo"
          class=""
        ></b-img>
        <h2 class="brand-text text-primary ml-1">Optimal</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-link href="/">
            <b-img
              :src="require('@/assets/images/avatars/optimalfull.png')"
              style="width: 200px"
              class="mb-3"
            />
          </b-link>

          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Selamat Datang! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan Login
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              method="post"
              @submit.prevent="login"
            >
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Masukkan Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Masukkan Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button type="submit" variant="primary" block> Login </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Belum Punya Akun? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Buat Akun</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // status: "",
      password: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    login(e) {
      e.preventDefault();
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$http
            .post(process.env.VUE_APP_BACKEND_URL + "login", {
              email: this.email,
              password: this.password,
            })
            .then((res) => {
              // console.log("ceck", res.data.success)
              if (res.data.success == true) {
                // set token
                localStorage.setItem("token", res.data.token);
//-----------------------------------BATAS-------------------------------------------------
            this.$http.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
            this.$http
            .get(process.env.VUE_APP_BACKEND_URL + "user")
            .then((response) => {

            this.cek_user = response;
            localStorage.setItem("Unama", response.data.nama);
            localStorage.setItem("Uemail", response.data.email);
            localStorage.setItem("Ulevel", response.data.level);
            localStorage.setItem("Ukampus", response.data.kampus);
            localStorage.setItem("Uid", response.data.id);
            this.getLevel = response.data.level;
            console.log("cek level", this.getLevel);
            if (this.getLevel == 0) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "user/dashboard"
              // this.$router.push({ name: "dashboard_user" });
            } else if (this.getLevel == 1) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "user/dashboard"
              // this.$router.push({ name: "dashboard_user" });
            } else if (this.getLevel == 2) {
              window.location.href = process.env.VUE_APP_FRONTEND_URL + "admin/dashboard"
              // this.$router.push({ name: "dashboard_admin" });
            } 
         
        });
//-----------------------------------BATAS-------------------------------------------------            
                // this.$router.push({ name: "dashboard" });
              } else {
                Swal.fire({
                  title: "Login Gagal?",
                  text: "Username/Password Salah",
                  icon: "warning",
                  confirmButtonText: "Ya, ulangi lagi!",
                });
              }
            })
            .catch((e) => {});
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
